//Turbo.session.drive = false
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'debounce'


import "jquery/dist/jquery.js";
import '@hotwired/turbo-rails'
import '@hotwired/stimulus'
import "flowbite/dist/flowbite.turbo.js";


import Rails from 'rails-ujs';
import * as ActiveStorage from 'activestorage';
import LocalTime from "local-time"
import '@fortawesome/fontawesome-free/js/all';

Rails.start();

ActiveStorage.start();
LocalTime.start()

import 'materialize-css/dist/js/materialize.min.js'
import 'controllers'
import "./application/javascripts/custom.js"
import "./application/javascripts/pages.js"
import "./application/javascripts/sales.js"
import "./channels"
